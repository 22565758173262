body {
	background: #f7f7f7;
}
.container-app {
	min-height: 100vh
}
.logo-gris{
	max-width: 172px;
}
.btn {
	&-intranet{
		box-shadow: none !important;
		&:hover,&[aria-expanded="true"],&:focus{
			background-color:$color-intranet;
			border-color:$color-intranet !important;
		}
	}
	&-tranferencia{
		box-shadow: none !important;
		&:hover,&[aria-expanded="true"],&:focus{
			background-color:$color-tranferencia;
			border-color:$color-tranferencia !important;
		}
	}
	&-contabilidad{
		box-shadow: none !important;
		&:hover,&[aria-expanded="true"],&:focus{
			background-color:$color-contabilidad;
			border-color:$color-contabilidad !important;
		}
	}
	&-bhes{
		box-shadow: none !important;
		&:hover,&[aria-expanded="true"],&:focus{
			background-color:$color-tranferencia;
			border-color:$color-tranferencia !important;
		}
	}
}
#nav-collapse-modulo{
	&-intranet{
		background-color:$color-intranet;
		& a, & a:visited {
			color: white !important;
		}
		& a:hover {
			color: black !important;
		}
	}
	&-tranferencia{
		background-color:$color-tranferencia;
		& a, & a:visited {
			color: white !important;
		}
		& a:hover {
			color: black !important;
		}
	}
	&-contabilidad{
		background-color:$color-contabilidad;
		& a, & a:visited {
			color: white !important;
		}
		& a:hover {
			color: black !important;
		}
	}
	&-bhes{
		background-color:$color-tranferencia;
		& a, & a:visited {
			color: white !important;
		}
		& a:hover {
			color: black !important;
		}
	}
}