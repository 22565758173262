.view-totales-consulta {
    width: 480px;
    position: fixed;
    bottom: 0;
    z-index: 1;
    right: 10px;
    margin-right: 10px;
    background-color: #E4E4E4;
    padding: 10px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.row-container-amount {
    display: inline-block;
}

.view-totales-consulta_montos {
    display: flex; 
    align-items: center; 
    justify-content: flex-end;
}
.lbl-greeen {
        color: #00BB2D;
    }
    
#div_table_not_amount {
    margin-bottom: 60px;
}