.frame {
    height: 100vh;
    .header-hrand {
      padding: 18px;
      font-size: 16px;
      height: 56px;
      background: #34c3ff;
      white-space: nowrap;
      overflow: hidden;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
    .rs-sidebar {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    }
    .rs-sidenav {
      flex: 1 1 auto;
      transition: none !important;
      border-top: 1px solid #e5e5ea;
      width: 100%;
    }
  
    .rs-content {
      padding: 20px;
    }
    .nav-toggle {
      border-top: 1px solid #eee;
    }
  
    .nav-item-active {
      color: !important;
    }
  }
  
@base-color: #f44336;